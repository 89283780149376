export default   {
    blocks: [
      {
        id: 'section',
        label: '<b>Section</b>',
        attributes: { class:'gjs-block-section' },
        content: `<section>
                    <h1>This is a simple title</h1>
                    <p>This is just a Lorem text: Lorem ipsum dolor sit amet</p>
                  </section>`,
      },
      {
        id: 'two-columns',
        label: '2 Columns',
        content: `<div class="row">
                    <div class="col-md-6">Column 1</div>
                    <div class="col-md-6">Column 2</div>
                  </div>`,
      },
      
      {
        id: 'three-columns',
        label: '3 Columns',
        content: `<div style="display:flex; flex-direction:row; justify-content:space-between;">
                    <div class="col-md-4">Column 1</div>
                    <div class="col-md-4">Column 2</div>
                    <div class="col-md-4">Column 3</div>
                  </div>`,
      },
      {
        id: 'four-columns',
        label: '4 Columns',
        content: `<div class="row">
                    <div class="col-md-3">Column 1</div>
                    <div class="col-md-3">Column 2</div>
                    <div class="col-md-3">Column 3</div>
                    <div class="col-md-3">Column 4</div>
                  </div>`,
      },
    ],
  };