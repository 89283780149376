import axios from 'axios';

const axiosInstance = axios.create({
    // baseURL: 'http://localhost:8000',
    baseURL : 'https://api.build.legionweb.co',
    // baseURL : 'http://192.168.1.103:8000',
    headers: { 
        'Content-Type': 'application/json',
    },
});
export const baseURL = 'https://api.build.legionweb.co';
// export const baseURL = 'http://localhost:8000';
// export const baseURL = 'http://localhost:8000';
// export const baseURL = 'http://192.168.1.103:8000';

export default axiosInstance;
