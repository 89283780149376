// import React, { useEffect, useState, useRef } from 'react';
// import grapesjs from 'grapesjs';
// import gjsGoogleMaterialIcons from 'grapesjs-google-material-icons';
// import 'grapesjs/dist/css/grapes.min.css';
// import gjsPresetWebpage from 'grapesjs-preset-webpage'; 
// import gjsForms from 'grapesjs-plugin-forms';
// import plugin from 'grapesjs-undraw';
// import 'grapesjs/dist/css/grapes.min.css';
// import 'grapesjs-undraw/dist/grapesjs-undraw.min.css'
// import 'grapesjs/dist/css/grapes.min.css';
// import styles from './editor.module.css';
// import MainLayout from '../../layouts/MainLayout';
// import axios from '../../utils/axiosConfig';
// import { useSelector } from 'react-redux';
// import { Dialog } from 'primereact/dialog';
// import {Button } from 'primereact/button';
// import { InputText } from 'primereact/inputtext';
// import { useLocation, useParams } from 'react-router-dom';
// import { Menu } from 'primereact/menu';
// import LoginRequireLayout from '../../layouts/LoginRequireLayout';
// import BLOCKS from './block.js'
// import editorComponents from './components.js';

// const VisualEditor = () => {
//   const [pages, setPages] = useState([]);
//   const [selectedPage, setSelectedPage] = useState(null);
//   const editorRef = useRef(null);
//   const token = useSelector((state) => state.auth.token);
//   const [modalVisible, setModalVisible] = useState(false);
//   const [newPageModalVisible, setNewPageModalVisible] = useState(false);
//   const [newPageName, setNewPageName] = useState('');
//   const { id: projectId } = useParams();
//   const menuRef = useRef(null);


//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);

//   // Récupérez la valeur du paramètre "next"
//   const type = queryParams.get('type');
//   useEffect(() => {       

//     // Ajout d'une animation pour le clic sur le lien
//     document.querySelectorAll('.nav-link').forEach(link => {
//         link.addEventListener('click', function() {
//             this.classList.add('active');
//             setTimeout(() => {
//                 this.classList.remove('active');
//             }, 200);
//         });
//     });

//   // Ferme le menu si l'utilisateur clique en dehors
//   document.addEventListener('click', function(event) {
//       const isDropdown = event.target.closest('.nav-item');
//       if (!isDropdown) {
//           const allDropdowns = document.querySelectorAll('.dropdown-menu');
//           allDropdowns.forEach(dd => dd.classList.remove('show'));
//       }
//   });



//     // Initialiser GrapesJS
//     editorRef.current = grapesjs.init({
//       container: '#gjs',
//       plugins: [gjsPresetWebpage, gjsForms, gjsGoogleMaterialIcons, plugin,],
//       pluginsOpts: {
//         "gjs-google-material-icons": {}
//       },
//       canvas: {
//         scripts: [
//           'https://code.jquery.com/jquery-3.6.0.min.js',
//           'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js'
//         ],
//         styles: [
//           'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css',
//           'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css',
//           'data:text/css;charset=utf-8;base64,' + btoa(`
//             .gjs-pn-panels, .gjs-pn-views-container, .gjs-pn-buttons, .gjs-block, .gjs-block-category {
//                 background-color: white !important;
//                 color: black !important;
//                 border: 1px solid #ddd !important;
//             }
      
//             .gjs-pn-btn, .gjs-btn-prim, .gjs-btn {
//                 background-color: #f8f9fa !important;
//                 color: #212529 !important;
//                 border: 1px solid #ccc !important;
//             }
      
//             .gjs-block:hover, .gjs-pn-btn:hover {
//                 background-color: #e9ecef !important;
//                 color: black !important;
//             }
      
//             .gjs-block {
//                 border-radius: 5px !important;
//                 padding: 10px !important;
//             }
//           `)
//         ],
//       },
      
//       fromElement: true,
//       height: '92%',
//       width: '100%',
//       storageManager: { type: 'inline' },
//       panels: { defaults: [] },
//       blockManager:BLOCKS

//     });


//     editorComponents(editorRef)

//     // Charger les pages du projet au démarrage
//     loadPages();

    

//     return () => {
//       editorRef.current.destroy();
//     };
//   }, []);

//   const loadPages = async () => {
//     try {
//       const response = await axios.get(`/${ type === "template" ? "templates" :"projects"}/${projectId}/pages-list/`, {
//         headers: { Authorization: `Bearer ${token.access}` },
//       });
//       setPages(response.data);
//       setSelectedPage(response.data[0]);
//       loadPageContent(response.data[0]?.id)
//     } catch (error) {
//       console.error('Erreur lors du chargement des pages:', error);
//     }
//   };

//   useEffect(() => {
//     if (selectedPage) {
//       loadPageContent(selectedPage.id);
//     }
//   }, [ loadPages, selectedPage]);

//   const loadPageContent = async (pageId) => {
//     try {
//       const response = await axios.get(`/page/${pageId}/`, {
//         headers: { Authorization: `Bearer ${token.access}` },
//       });
      
//           const { html_content, css_content, js_content } = response.data;
//           editorRef.current.setComponents(html_content);
//           editorRef.current.setStyle(css_content);
//           // add js content on editor refresh

          
          

//           editorRef.Storage.add('inline', {
//             load() {
//               return JSON.parse(html_content || '{}');
//             },
//             store(data) {
//               const component = editorRef.Pages.getSelected().getMainComponent();
//               const test = JSON.stringify(data);
//               const test2 = `<html>
//                 <head>
//                   <style>${editorRef.getCss({ component })}</style>
//                 </head>
//                 ${editorRef.getHtml({ component })}
//               <html>`;
//               console.log(test);
//               console.log(test2);
              
              
//             }
//           });
//     } catch (error) {
//       console.error('Erreur lors du chargement du contenu de la page:', error);
//     }
//   };

//   const savePageContent = async (id) => {
//     if (selectedPage) {
//       try {
//         const htmlContent = editorRef.current.getHtml();
//         const cssContent = editorRef.current.getCss();
//         // JS content can be handled similarly if you want to capture custom JS
//         await axios.patch(`/pages/${id}/`, {
//           html_content: htmlContent,
//           css_content: cssContent,
//           js_content: editorRef.current.getJs(), // Include js_content if necessary
          
//         }, {
//           headers: { Authorization: `Bearer ${token.access}` },
//         });
//       } catch (error) {
//         console.error('Erreur lors de la sauvegarde de la page:', error);
//       }
//     }
//   };


//   const handlePageSelect = (page) => {
//     setSelectedPage(page);
//   };

//   const handleAddPage = async () => {
//     if (newPageName) {
//       try {
//         let data = { name: newPageName,  html_content:"<h1>"+newPageName+"</h1>", css_content:"" }
//         if (type === "template") {
//           data = {...data, templte:projectId }
//         } else {
//           data = {...data, project:projectId }
//         }
//         const response = await axios.post(`/${ type==="template"?"templates": "projects"}/${projectId}/pages/`, data, {
//           headers: { Authorization: `Bearer ${token.access}` },
//         });
//         const newPage = response.data;
//         setPages([...pages, newPage]);
//         setSelectedPage(newPage);
//         setNewPageModalVisible(false);
//       } catch (error) {
//         console.error('Erreur lors de la création de la page:', error);
//       }
//     }
//   };

//   const handleRenamePage = async (pageId) => {
//     if (newPageName) {
//       try {
//         const response = await axios.patch(`/pages/${pageId}/`, { name: newPageName }, {
//           headers: { Authorization: `Bearer ${token.access}` },
//         });
//         const updatedPage = response.data;
//         setPages(pages.map(page => (page.id === pageId ? updatedPage : page)));
//         setSelectedPage(updatedPage);
//       } catch (error) {
//         console.error('Erreur lors du renommage de la page:', error);
//       }
//     }
//   };

//   const handleDeletePage = async (pageId) => {
//     try {
//       await axios.delete(`/pages/${pageId}/delete/`, {
//         headers: { Authorization: `Bearer ${token.access}` },
//       });
//       setPages(pages.filter(page => page.id !== pageId));
//       setSelectedPage(null);
//     } catch (error) {
//       console.error('Erreur lors de la suppression de la page:', error);
//     }
//   };

//   const openContextMenu = (e, page) => {
//     e.preventDefault();
//     menuRef.current.toggle(e);
//     setSelectedPage(page);
//   };



//   return (
//     <MainLayout>
//       <LoginRequireLayout></LoginRequireLayout>
//       <div className={styles.editorContainer}>
//         <div className={styles.sidebar}>
//           <h2 className={styles.sidebarTitle}>Pages du projet</h2>
//           <ul className={styles.pageList}>
//             {pages.map((page, index) => (
//               <li
//                 key={index}
//                 className={`${styles.pageItem} ${selectedPage?.id === page.id ? styles.activePage : ''}`}
//                 onContextMenu={(e) => openContextMenu(e, page)}
//                 onClick={() => handlePageSelect(page)}
//               >
//                 {page.name}
//               </li>
//             ))}
//           </ul>
//           <Button className={styles.addPageButton} onClick={e=>{setNewPageName('');setNewPageModalVisible(!newPageModalVisible)}}>Ajouter une page</Button>
//         </div>

//         <div className={styles.editorArea}>
//           <div id="gjs" style={{ height: '100vh', overflow: 'hidden', color:"white" }}>
//             <h1 className={styles.pageTitle}>{selectedPage?.name}</h1>
//           </div>
//         </div>



//         {/* Menu contextuel */}
//         <div id="context-menu" className={styles.contextMenu}>
//         </div>
//         <div className="card flex justify-content-center">
//         <div>
//             <Menu model={[
//               {
//                 label: 'Renommer', icon: 'pi pi-pencil', command: () => { setNewPageName(selectedPage?.name || ''); setModalVisible(true); }
//               },
//               {
//                 label: 'Supprimer', icon: 'pi pi-trash', command: () => { handleDeletePage(selectedPage?.id); }
//               },
//               {
//                 label: 'Sauvegarder', icon: 'pi pi-save', command: () => { savePageContent(selectedPage?.id); }
//               }
//             ]} popup ref={menuRef} />
//         </div>
//         </div>

//         {/* Modal de renommage */}
//         <Dialog header="Renommer la page" visible={modalVisible} onHide={() => setModalVisible(false)}>
//           <input
//             type="text"
//             value={newPageName}
//             onChange={(e) => setNewPageName(e.target.value)}
//             placeholder="Nouveau nom de page"
//           />
//           <Button onClick={() => { handleRenamePage(selectedPage?.id); setModalVisible(false); }}>Renommer</Button>
//         </Dialog>


//          {/* Modal d'ajout */}
//          <Dialog header="Nouvelle page" visible={newPageModalVisible} onHide={() => setNewPageModalVisible(false)} >
//           <div  style={{display:"flex", flexDirection:"column", justifyContent:"space-between", gap:"20px"}}>
//             <InputText
//             type="text"
//             value={newPageName}
//             onChange={(e) => setNewPageName(e.target.value)}
//             placeholder="Nom de page"
//           />
//           <Button onClick={() => { handleAddPage();  }}>Enregistrer</Button>
//           </div>
//         </Dialog>
//       </div>
//     </MainLayout>
//   );
// };

// export default VisualEditor;










import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Menu } from 'primereact/menu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import grapesjs from 'grapesjs';
import gjsGoogleMaterialIcons from 'grapesjs-google-material-icons';
import gjsPresetWebpage from 'grapesjs-preset-webpage'; 
import gjsForms from 'grapesjs-plugin-forms';
import gjsUndrawPlugin from 'grapesjs-undraw';

import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-undraw/dist/grapesjs-undraw.min.css';

import axios from '../../utils/axiosConfig';
import MainLayout from '../../layouts/MainLayout';
import LoginRequireLayout from '../../layouts/LoginRequireLayout';
import styles from './editor.module.css';

import BLOCKS from './block.js';
import editorComponents from './components.js';

// Fonction de validation des inputs
const validateInput = (input) => {
  // Validation basique : 
  // - Longueur entre 3 et 50 caractères
  // - Autorise lettres, chiffres, espaces et traits d'union
  const inputRegex = /^[a-zA-Z0-9\s-]{3,50}$/;
  return inputRegex.test(input.trim());
};


// Nouveau hook personnalisé pour la sauvegarde automatique
const useAutoSave = (editor, selectedPage, token, showSuccess, showError) => {
  const [autoSaveInterval, setAutoSaveInterval] = useState(null);
  const [lastSavedContent, setLastSavedContent] = useState(null);

  // Configuration de sauvegarde automatique
  const AUTO_SAVE_DELAY = 5000; // Toutes les 5 secondes
  const CONTENT_CHANGE_DELAY = 2000; // 2 secondes après la dernière modification

  // Fonction de sauvegarde
  const saveContent = useCallback(async () => {
    if (!selectedPage) return;

    try {
      const htmlContent = editor.getHtml();
      const cssContent = editor.getCss();

      // Vérifie si le contenu a changé
      const currentContent = JSON.stringify({ htmlContent, cssContent });
      if (currentContent === lastSavedContent) return;

      await axios.patch(`/pages/${selectedPage.id}/`, {
        html_content: htmlContent,
        css_content: cssContent,
      }, {
        headers: { Authorization: `Bearer ${token.access}` },
        timeout: 5000
      });

      showSuccess("Sauvegarde automatique effectuée");
      setLastSavedContent(currentContent);
    } catch (error) {
      showError("Échec de la sauvegarde automatique");
      console.error('Erreur de sauvegarde automatique:', error);
    }
  }, [selectedPage, token, lastSavedContent, showSuccess, showError]);

  // Configuration de l'intervalle de sauvegarde
  useEffect(() => {
    if (!editor || !selectedPage) return;

    // Arrête tout intervalle existant
    if (autoSaveInterval) clearInterval(autoSaveInterval);

    // Nouvel intervalle de sauvegarde
    const newInterval = setInterval(saveContent, AUTO_SAVE_DELAY);
    setAutoSaveInterval(newInterval);

    // Nettoyage à la destruction
    return () => {
      if (newInterval) clearInterval(newInterval);
    };
  }, [editor, selectedPage, saveContent]);

  // Détection des changements avec debounce
  useEffect(() => {
    if (!editor) return;

    let changeTimeout;
    const handleChange = () => {
      // Annule le timeout précédent
      if (changeTimeout) clearTimeout(changeTimeout);

      // Nouveau timeout
      changeTimeout = setTimeout(saveContent, CONTENT_CHANGE_DELAY);
    };

    // Ajout des écouteurs
    editor.on('component:update', handleChange);
    editor.on('style:update', handleChange);

    // Nettoyage
    return () => {
      editor.off('component:update', handleChange);
      editor.off('style:update', handleChange);
      if (changeTimeout) clearTimeout(changeTimeout);
    };
  }, [editor, saveContent]);

  return { autoSaveInterval };
};
const VisualEditor = () => {
  const toastRef = useRef(null);
  const editorRef = useRef(null);
  const menuRef = useRef(null);

  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [newPageModalVisible, setNewPageModalVisible] = useState(false);
  const [newPageName, setNewPageName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const { id: projectId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');

  // Toast handlers
  const showSuccess = useCallback((message) => {
    toastRef.current.show({
      severity: 'success', 
      summary: 'Succès', 
      detail: message, 
      life: 3000 
    });
  }, []);

  const showError = useCallback((message) => {
    toastRef.current.show({
      severity: 'error', 
      summary: 'Erreur', 
      detail: message, 
      life: 3000 
    });
  }, []);

  const { autoSaveInterval } = useAutoSave(
    editorRef.current, 
    selectedPage, 
    token, 
    showSuccess, 
    showError
  );

  // Chargement des pages sécurisé
  const loadPages = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/${type === "template" ? "templates" : "projects"}/${projectId}/pages-list/`, {
        headers: { Authorization: `Bearer ${token.access}` },
        timeout: 10000 // Timeout de 10 secondes
      });
      
      if (response.data.length === 0) {
        showError("Aucune page trouvée");
        return;
      }

      setPages(response.data);
      const firstPage = response.data[0];
      setSelectedPage(firstPage);
      await loadPageContent(firstPage.id);
    } catch (error) {
      showError(error.response?.data?.detail || "Impossible de charger les pages");
      console.error('Erreur lors du chargement des pages:', error);
    } finally {
      setIsLoading(false);
    }
  }, [projectId, type, token, showError]);

  // Chargement du contenu de page sécurisé
  const loadPageContent = useCallback(async (pageId) => {
    if (!pageId) return;

    setIsLoading(true);
    try {
      const response = await axios.get(`/page/${pageId}/`, {
        headers: { Authorization: `Bearer ${token.access}` },
        timeout: 10000
      });
      
      const { html_content, css_content } = response.data;
      
      if (editorRef.current) {
        editorRef.current.setComponents(html_content || '');
        editorRef.current.setStyle(css_content || '');
      }
    } catch (error) {
      showError("Impossible de charger le contenu de la page");
      console.error('Erreur lors du chargement du contenu:', error);
    } finally {
      setIsLoading(false);
    }
  }, [token, showError]);

  // Sauvegarde du contenu avec validation
  const savePageContent = useCallback(async (id) => {
    if (!selectedPage) return;

    try {
      const htmlContent = editorRef.current.getHtml();
      const cssContent = editorRef.current.getCss();

      await axios.patch(`/pages/${id}/`, {
        html_content: htmlContent,
        css_content: cssContent,
      }, {
        headers: { Authorization: `Bearer ${token.access}` },
        timeout: 10000
      });

      showSuccess("Page sauvegardée avec succès");
    } catch (error) {
      showError("Erreur lors de la sauvegarde");
      console.error('Erreur de sauvegarde:', error);
    }
  }, [selectedPage, token, showSuccess, showError]);



  const handleRenamePage = async (pageId) => {
    if (newPageName) {
      try {
        const response = await axios.patch(`/pages/${pageId}/`, { name: newPageName }, {
          headers: { Authorization: `Bearer ${token.access}` },
        });
        const updatedPage = response.data;
        setPages(pages.map(page => (page.id === pageId ? updatedPage : page)));
        setSelectedPage(updatedPage);
      } catch (error) {
        console.error('Erreur lors du renommage de la page:', error);
      }
    }
  };
  // Ajout de page avec validation
  const handleAddPage = useCallback(async () => {
    if (!validateInput(newPageName)) {
      showError("Validation basique : \
   - Longueur entre 3 et 50 caractères\
   - Autorise lettres, chiffres, espaces et traits d'unionNom de page invalide");
      return;
    }

    try {
      const data = { 
        name: newPageName.trim(), 
        html_content: `<h1>${newPageName}</h1>`, 
        css_content: "",
        ...(type === "template" ? { template: projectId } : { project: projectId })
      };

      const response = await axios.post(`/${type === "template" ? "templates" : "projects"}/${projectId}/pages/`, data, {
        headers: { Authorization: `Bearer ${token.access}` },
        timeout: 10000
      });

      const newPage = response.data;
      setPages(prev => [...prev, newPage]);
      setSelectedPage(newPage);
      setNewPageModalVisible(false);
      showSuccess("Page ajoutée avec succès");
    } catch (error) {
      showError("Erreur lors de la création de la page");
      console.error('Erreur de création:', error);
    }
  }, [newPageName, projectId, type, token, showSuccess, showError]);

  // Initialisation de l'éditeur avec optimisations
  useEffect(() => {
    const initEditor = () => {
      editorRef.current = grapesjs.init({
        container: '#gjs',
        mobileMode: true,
        touchStart: true,
        multipleSelection: false,
        handlers: ['touch', 'pointer', 'mouse'],
        selectorManager: {
          custom: true
        },
        plugins: [gjsPresetWebpage, gjsForms, gjsGoogleMaterialIcons, gjsUndrawPlugin],
        height: '100%',
        width: '100%',
        storageManager: { type: 'none' },
        blockManager: BLOCKS,
        responsive: true, 
        panels: {
          defaults: [
            {
              id: 'views',
              buttons: [
                {
                  id: 'open-code',
                  visibility: 'hidden' // Cache le bouton de vue du code
                },
                {
                  id: 'export',
                  visibility: 'hidden' // Cache le bouton d'export/téléchargement
                }
              ]
            }
          ]
        },
        
      });

      editorComponents(editorRef);
    };

    initEditor();
    loadPages();

    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, [loadPages]);

  // Optimisation des rendus
  const contextMenuItems = useMemo(() => [
    {
      label: 'Renommer', 
      icon: 'pi pi-pencil', 
      command: () => { 
        setNewPageName(selectedPage?.name || ''); 
        setModalVisible(true); 
      }
    },
    {
      label: 'Supprimer', 
      icon: 'pi pi-trash', 
      command: () => {
        confirmDialog({
          message: 'Voulez-vous vraiment supprimer cette page ?',
          header: 'Confirmation de suppression',
          icon: 'pi pi-exclamation-triangle',
          accept: () => handleDeletePage(selectedPage?.id)
        });
      }
    },
    {
      label: 'Sauvegarder', 
      icon: 'pi pi-save', 
      command: () => savePageContent(selectedPage?.id)
    }
  ], [selectedPage, savePageContent]);

  const openContextMenu = (e, page) => {
    e.preventDefault();
    menuRef.current.toggle(e);
    setSelectedPage(page);
  };


  useEffect(() => {
    if (selectedPage) {
      loadPageContent(selectedPage.id);
    }
  }, [ loadPages, selectedPage]);




  // Suppression de page sécurisée
  const handleDeletePage = useCallback(async (pageId) => {
    try {
      await axios.delete(`/pages/${pageId}/delete/`, {
        headers: { Authorization: `Bearer ${token.access}` },
        timeout: 10000
      });

      setPages(prev => prev.filter(page => page.id !== pageId));
      setSelectedPage(null);
      showSuccess("Page supprimée avec succès");
    } catch (error) {
      showError("Erreur lors de la suppression de la page");
      console.error('Erreur de suppression:', error);
    }
  }, [token, showSuccess, showError]);

  return (
    <MainLayout>
      <LoginRequireLayout />
      <Toast ref={toastRef} />
      <ConfirmDialog />
      
      <div className={`${styles.editorContainer} ${styles.mobileResponsive}`}>
        <div className={styles.sidebar}>
          <h2 className={styles.sidebarTitle}>Pages du projet</h2>
          {isLoading ? (
            <div>Chargement...</div>
          ) : (
            <>
              <ul className={styles.pageList}>
                {pages.map((page) => (
                  <li
                    key={page.id}
                    className={`${styles.pageItem} ${selectedPage?.id === page.id ? styles.activePage : ''}`}
                    onClick={() => setSelectedPage(page)}
                    onContextMenu={(e) => openContextMenu(e, page)}
                  >
                    {page.name}
                  </li>
                ))}
              </ul>
              <Button 
                label="Ajouter une page" 
                onClick={() => {
                  setNewPageName('');
                  setNewPageModalVisible(true);
                }} 
              />
            </>
          )}
        </div>

        <div className={styles.editorArea}>
          <div id="gjs" />
        </div>

        <Menu model={contextMenuItems} popup ref={menuRef} />

        {/* Modales */}
        <Dialog 
          header="Renommer la page" 
          visible={modalVisible} 
          onHide={() => setModalVisible(false)}
        >
          <InputText
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            placeholder="Nouveau nom de page"
            className="w-full"
          />
          <Button 
            label="Renommer" 
            onClick={() => {
              if (validateInput(newPageName)) {
                handleRenamePage(selectedPage?.id);
                setModalVisible(false);
              } else {
                showError("Nom de page invalide");
              }
            }} 
          />
        </Dialog>

        <Dialog 
          header="Nouvelle page" 
          visible={newPageModalVisible} 
          onHide={() => setNewPageModalVisible(false)}
        >
          <InputText
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            placeholder="Nom de page"
            className="w-full"
          />
          <Button 
            label="Enregistrer" 
            onClick={handleAddPage} 
          />
        </Dialog>
      </div>
    </MainLayout>
  );
};

export default React.memo(VisualEditor);