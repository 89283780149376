
'use client'

import MainLayout from '../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import axios from '../utils/axiosConfig';
import React, { useEffect, useState } from 'react';
import './admin.css';

const Page = () => {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.auth.profile);
  const [userInfo, setUserInfo] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const { setValue, formState: { errors } } = useForm({
    defaultValues: userInfo,
  });

  useEffect(() => {
    async function isadmin() {
      try {
        const response = await axios.get(`/users/${profile.id}`, {
          headers: {
            'Authorization': `Bearer ${token.access}`,
          },
        });
        setUserInfo(response.data);
        Object.keys(response.data).forEach(key => {
          setValue(key, response.data[key]);
        });
        if (userInfo.is_superuser != 1) {
          navigate('/home');
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations utilisateur", error);
      }
    };
    isadmin();
  }, []);

  return (
    <MainLayout>
      <div className='page1' style={{paddingTop:"3rem", paddingBottom:"4rem" }}>
        <div className='contain1'>
          <div className='grid-container'>
            <div className='item' onClick={() => navigate("/admin/statistiques")} >
              <div className='headstat'></div>
              <button className='text'>
                <center><h3>Statistiques</h3></center>
              </button>
            </div>

            <div className='item' onClick={() => navigate("/admin/utilisateur")}>
              <div className='headuser'></div>
              <button  className='text'>
                <center><h3>Users</h3></center>
              </button>
            </div>

            <div className='item' onClick={() => navigate("/admin/templates")} >
              <div className='headsettings'></div>
              <button className='text'>
                <center><h3>Templates</h3></center>
              </button>
            </div>

            <div className='item'>
              <div className='headsites'></div>
              <button onClick={() => navigate("/admin/sites")} className='text'>
                <center><h3>Sites</h3></center>
              </button>
            </div>

            <div className='item' onClick={() => navigate("/admin/appliweb")}>
              <div className='headappliweb'></div>
              <button  className='text'>
                <center><h3>Applis Web</h3></center>
              </button>
            </div>

            <div className='item' onClick={() => navigate("/admin/applimobil")}>
              <div className='headapplimobil'></div>
              <button  className='text'>
                <center><h3>Applis Mobiles</h3></center>
              </button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Page;
