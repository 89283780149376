'use client';

import { useState, useEffect } from 'react';
import axios from '../../utils/axiosConfig';
import MainLayout from '../../layouts/MainLayout';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import './statistiques.css';

const Statistiques = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const [users, setUsers] = useState(null);
  const [web, setWeb] = useState(null);
  const [mobil, setMobil] = useState(null);
  const [appliweb, setAppweb] = useState(null);

  const fetchData = async (url, setState, key) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token.access}`,
        },
      });
      setState(response.data[key]);
    } catch (error) {
      console.error(`Erreur lors de la récupération des données de ${url}`, error);
      alert(`Erreur : ${error.message}`);
    }
  };

  useEffect(() => {
    fetchData('users/number/', setUsers, 'user_count');
    fetchData('projects/sites', setWeb, 'web_projects_count');
    fetchData('projects/sites', setAppweb, 'web_projects_count');
    fetchData('projects/applimobil', setMobil, 'mobil_projects_count');
  }, [token.access]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <MainLayout>
      <div className="page-container">
        {/* Description de la page */}
        <header className="page-header">
          <h1>
            <i className="pi pi-chart-bar gold-icon"></i> Statistiques
          </h1>
          <p>
            Explorez les indicateurs clés de performances et obtenez un aperçu
            rapide des utilisateurs, des applications et des projets réalisés.
          </p>
        </header>

        {/* Grille des statistiques */}
        <div className="statistics-grid">
          <StatCard
            title="Utilisateurs"
            value={users}
            icon="pi pi-users"
          />
          <StatCard
            title="Revenus"
            icon="pi pi-dollar"
            onClick={() => navigate('./revenus')}
            clickable
          />
          <StatCard
            title="Total Apps"
            value={mobil + web + appliweb}
            icon="pi pi-th-large"
          />
          <StatCard
            title="Sites Web"
            value={web}
            icon="pi pi-globe"
          />
          <StatCard
            title="Applications Web"
            value={appliweb}
            icon="pi pi-desktop"
          />
          <StatCard
            title="Applications Mobiles"
            value={mobil}
            icon="pi pi-mobile"
          />
        </div>

        {/* Bouton de retour en haut */}
        <Button
          icon="pi pi-arrow-up"
          className="scroll-top-btn p-button-rounded p-button-warning"
          onClick={scrollToTop}
          tooltip="Retour en haut"
        />
      </div>
    </MainLayout>
  );
};

const StatCard = ({ title, value, icon, onClick, clickable = false }) => (
  <div
    className={`stat-card ${clickable ? 'clickable' : ''}`}
    onClick={clickable ? onClick : null}
  >
    <div className="card-header">
      <i className={`${icon} gold-icon`}></i>
      <h4>{title}</h4>
    </div>
    <div className="card-body">
      <h1>{value !== null ? value : '--'}</h1>
    </div>
  </div>
);

export default Statistiques;
