'use client';

import './appliweb.css';
import MainLayout from '../../layouts/MainLayout';
import axios from '../../utils/axiosConfig';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

const Utilisateur = () => {
  const token = useSelector((state) => state.auth.token);
  const [users, setUsers] = useState([]);

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get('/projects/webaplist', {
        headers: {
          Authorization: `Bearer ${token.access}`,
        },
      });
      setUsers(response.data);
    } catch (error) {
      console.error("Erreur lors de la récupération des informations des applications web", error);
      alert("Erreur : " + error.message);
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, [token.access]);

  return (
    <MainLayout>
      <div className="page-container">
        {/* Description de la page */}
        <header className="page-header">
          <h1>
            <i className="pi pi-globe gold-icon"></i> Liste des Applications Web
          </h1>
          <p>
            Consultez les applications web enregistrées, avec leurs propriétaires et leurs dates de création.
          </p>
        </header>

        {/* Table des applications web */}
        <div className="table-container">
          <AppTable apps={users} />
        </div>

        {/* Bouton de retour en haut */}
        <Button
          icon="pi pi-arrow-up"
          className="scroll-top-btn p-button-rounded p-button-warning"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          tooltip="Retour en haut"
        />
      </div>
    </MainLayout>
  );
};

const AppTable = ({ apps }) => {
  const dt = useRef(null);
  const [selectedApps, setSelectedApps] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const header = (
    <div className="table-header">
      <h2>
        <i className="pi pi-search"></i> Rechercher
      </h2>
      <InputText
        type="search"
        onInput={(e) => setGlobalFilter(e.target.value)}
        placeholder="Rechercher..."
        className="p-inputtext-sm"
      />
    </div>
  );

  return (
    <DataTable
      ref={dt}
      value={apps}
      selection={selectedApps}
      onSelectionChange={(e) => setSelectedApps(e.value)}
      dataKey="id"
      paginator
      rows={10}
      rowsPerPageOptions={[5, 10, 25]}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Affiche {first} à {last} sur {totalRecords} applications"
      globalFilter={globalFilter}
      header={header}
      className="p-datatable-gridlines"
    >
      <Column selectionMode="multiple" exportable={false}></Column>
      <Column field="name" header="Nom de l'application" sortable style={{ minWidth: '14rem' }}></Column>
      <Column field="created_by" header="Propriétaire" sortable style={{ minWidth: '16rem' }}></Column>
      <Column field="created_at" header="Date de création" sortable style={{ minWidth: '16rem' }}></Column>
    </DataTable>
  );
};

export default Utilisateur;
